import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const checkScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset;
    const element = document.querySelector(".home-content");
    const elementOffset = element.offsetTop;
  
    if (scrollY > elementOffset - window.innerHeight / 1) {
      setShouldAnimate(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    checkScroll();
  
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className={`home-content ${shouldAnimate ? "show" : ""}`}>
          <Row>
            <Col md={7} className="home-header">
              
              <h1 className="heading-name">

                <strong className="main-name"> Dafa Hentra</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>
            
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;