import React, {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";

function ProjectCards(props) {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const checkScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset;
    const element = document.querySelector(".project-card-view");
    const elementOffset = element.offsetTop;
  
    if (scrollY > elementOffset - window.innerHeight / 1) {
      setShouldAnimate(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    checkScroll();
  
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <Card className={`project-card-view ${shouldAnimate ? "show" : ""}`}>
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"Demo"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
