import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import iCareer from "../../Assets/Projects/iCareer.png";
import NextProject from "../../Assets/Projects/NextProject.png";
import GrandDoor from "../../Assets/Projects/GrandDoor.png"

function Projects() {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const checkScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset;
    const element = document.querySelector(".project-card-view");
    const elementOffset = element.offsetTop;

    if (scrollY > elementOffset - window.innerHeight / 1) {
      setShouldAnimate(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    checkScroll();
  
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <Container fluid className={`project-section ${shouldAnimate ? "show" : ""}`}>
      <Particle />
      <Container>
        <h1 className="project-heading">
          Recent & Upcoming <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Everyday I'm always researching for fresh idea to my little project I made
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={iCareer}
              isBlog={false}
              title="iCareer"
              description="Career platform for jobseeker to develop their skillset through an online course teached by certified professionals with great amount of experiences."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={GrandDoor}
              isBlog={false}
              title="Grand Door"
              description="Grand door delivers immersive horror-mystery experiences that thrill and challenge. For those seeking adrenaline-pumping entertainment, we craft escape room games infused with spine-tingling narratives and mind-bending puzzles."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={NextProject}
              isBlog={false}
              title="Upcoming Project"
              description="It's still on process, stay tune!"            
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={NextProject}
              isBlog={false}
              title="Upcoming Project"
              description="It's still on process, stay tune!"            
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={NextProject}
              isBlog={false}
              title="Upcoming Project"
              description="It's still on process, stay tune!"            
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
