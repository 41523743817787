import React from "react";
import Particles from "react-tsparticles";

function Particle() {
  const particleConfig = {
    particles: {
      number: {
        value: 10, // Adjust the number of particles as needed
        density: {
          enable: false,
          value_area: 100, // Increase or decrease for particle density
        },
      },
      color: {
        value: "#ffffff", // Color of the particles (white in this case)
      },
      shape: {
        type: "star", // Shape of the particles (you can use "circle" or other shapes)
        stroke: {
          width: 0,
          color: "#000000",
        },
        polygon: {
          nb_sides: 5, // Number of sides for the star shape
        },
      },
      opacity: {
        value: 0.1, // Set the opacity to 50% (adjust as needed)
        random: true,
        anim: {
          enable: true,
          speed: 1,
          opacity_min: 0.1,
          sync: false,
        },
      },
      size: {
        value: 2, // Adjust the size of the stars
        random: true,
        anim: {
          enable: true,
          speed: 2,
          size_min: 0,
          sync: true,
        },
      },
      line_linked: {
        enable: true, // Disable lines between particles
      },
      move: {
        enable: true,
        speed: 2, // Adjust the speed of the falling stars
        direction: "bottom", // Make particles move from top to bottom
        random: true,
        straight: false,
        out_mode: "in",
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200,
        },
      },
    },
    interactivity: {
      detect_on: "canvas",
      events: {
        onhover: {
          enable: false,
        },
        onclick: {
          enable: false,
        },
        resize: true,
      },
    },
    retina_detect: true,
  };

  const trailConfig = {
    interactivity: {
      detectsOn: "window",
      events: {
        onClick: {
          enable: false,
          mode: [],
        },
        onDiv: {
          selectors: [],
          enable: false,
          mode: [],
          type: "line",
        },
        onHover: {
          enable: true,
          mode: "trail",
          parallax: {
            enable: false,
            force: 2,
            smooth: 10,
          },
        },
        resize: {
          delay: 0.5,
          enable: true,
        },
      },
      modes: {
        trail: {
          delay: 0.005,
          pauseOnStop: true,
          quantity: 3,
          particles: {
            color: {
              value: "#ffffff", // Set trail color to white
              animation: {
                enable: true,
                speed: 300,
                sync: true,
              },
            },
            collisions: {
              enable: false,
            },
            links: {
              enable: false,
            },
            move: {
              outModes: {
                default: "destroy",
              },
              speed: 2,
            },
            opacity: {
              value: 0.8, // Set trail opacity to 50% (adjust as needed)
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 3,
              animation: {
                enable: true,
                speed: 5,
                minimumValue: 1,
                sync: true,
                startValue: "min",
                destroy: "max",
              },
            },
          },
          mode: {
            value: "line", // Change trail mode to "line"
          },
        },
        // ... (rest of your modes)
      },
    },
    // ... (Your existing particle configuration)
  };

  return (
    <div>
      <Particles id="stars" options={particleConfig} />
      <Particles id="tsparticles" options={trailConfig} />
    </div>
  );
}

export default Particle;
