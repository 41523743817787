import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";

function About() {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const checkScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset;
    const element = document.querySelector(".about-section");
    const elementOffset = element.offsetTop;

    if (scrollY > elementOffset - window.innerHeight / 1) {
      setShouldAnimate(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    checkScroll();
  
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <Container fluid className={`about-section ${shouldAnimate ? "show" : ""}`}>
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              A Little <strong className="purple">Summary</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <h1 className="project-heading">
          These are <strong className="purple">My Tools </strong>
        </h1>

        <Toolstack />

      </Container>
    </Container>
  );
}

export default About;
