import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import {
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const checkScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset;
    const element = document.querySelector(".home-content");
    const elementOffset = element.offsetTop;
  
    if (scrollY > elementOffset - window.innerHeight / 1) {
      setShouldAnimate(true);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    checkScroll();
  
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <Container fluid className={`home-about-section ${shouldAnimate ? "show" : ""}`} id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              What <span className="purple"> You Should </span> Know
            </h1>
            <p className="home-about-body">
              I am a 17 years old boy from Indonesia and
              I am passionate in learning business intricacies
              <br />
              <br />I am a
              <i>
                <b className="purple"> Team-Oriented, Creative,</b> and 
                <i>
                  <b className="purple"> Quick Learner</b>
                </i>
              </i>
              <br />
              <br />
              In this programming world, I am more into
              <i>
                <b className="purple"> Web Development </b> and
                also{" "}
                <b className="purple">
                  Blockchain Network.
                </b>
              </i>
              <br />
              <br />
              I also seeking to expanding my skillset
              through <b className="purple">New Experiences</b> such as
              <i>
                <b className="purple">
                  {" "}
                  Internship
                </b>
              </i>
              &nbsp; and
              <i>
                <b className="purple"> Volunteering</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Know Me More!</h1>
            <p>
              Let's <span className="purple">collaborate </span> together
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://twitter.com/aesketik"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/dafahentra/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/dapahentra"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
